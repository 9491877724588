.searchat-main-menu-chatbot{
    display: inline-block;
    width: 50%;
    height:100px;
    padding: 20px;

    cursor: pointer;
    text-align: center;
    font-size: 18px;
    border: 1px solid #eee;
    transition:all 1s;
   -webkit-transition:all 1s;
   -moz-transition:all 1s;
}


.searchat-main-menu-chatbot:hover{
    background-color: #eee;
    border-radius:50%;
}