




.product-price {
  position: absolute;
  background: #CD853F;
  padding: 7px 20px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  font-weight: 200;
  color: #fff;
  border-radius: 7px;
  margin-top: 0px;
  margin-left: -5px;
 
  border-top-right-radius: 0px;
}

.product-price b {
  margin-left: 5px;
}

.add-to-cart-icon{
  position: absolute;
  bottom: -10px;
  right: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
 
  border-radius: 30px;
  background-color: #4D6EF5;
  color: white;
  height: 40px;
  width: 40px;
  font-size: 16px;
  cursor: pointer;
  
}

.add-to-cart-icon:hover{
  background-color: #3159f7;
}


.desc-item-icon{
  position: absolute;
  bottom: -10px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
 
  border-radius: 30px;
  background-color: #4D6EF5;
  color: white;

  height: 40px;
  width: 40px;
  font-size: 16px;
  cursor: pointer;
}

.desc-item-icon:hover{
  background-color: #3159f7;
}

.ask-item-icon{
  position: absolute;
  bottom: -10px;
  right: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
 
  border-radius: 30px;
  background-color: #4D6EF5;
  color: white;

  height: 40px;
  width: 40px;
  font-size: 16px;
  cursor: pointer;
}
.ask-item-icon:hover{
  background-color: #3159f7;
}


.read-card-icon{

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 30px;
  background-color: #4D6EF5;
  color: white;
  
  height: 40px;
  width: 40px;
  font-size: 16px;
  cursor: pointer;
}

.read-card-icon:hover{
  background-color: #3159f7;
}


.back-to-main-card{

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 30px;
  background-color: #4D6EF5;
  color: white;

  height: 40px;
  width: 40px;
  font-size: 16px;
  cursor: pointer;
}

.back-to-main-card:hover{
  background-color: #3159f7;
}
.rate-product-icon{
  position: absolute;
  bottom: -10px;
  right: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 30px;
  background-color: #FFD700;
  color: white;
  height: 40px;
  width: 40px;
  font-size: 16px;
  cursor: pointer;
}

.rate-product-icon:hover{
  background-color: #e2bd00;
}


.fav-product-icon{
  position: absolute;
  bottom: -10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 30px;
  background-color: 	#f388bd;
  color: white;
  height: 40px;
  width: 40px;
  font-size: 16px;
  cursor: pointer;
}

.fav-product-icon:hover{
  background-color: #e25f9e;
}


.share-product-icon{
  position: absolute;
  bottom: -10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
 
  border-radius: 30px;
  background-color: 	#eee;
  color: black;
  height: 40px;
  width: 40px;
  font-size: 16px;
  cursor: pointer;
}

.share-product-icon:hover{
  background-color: gray;
  color: black;
}




/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
 
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;


}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid red;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: red;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}