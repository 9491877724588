.searchat-chatbot-menu{
    background-color: white;
    height: 280px;
    position: absolute;
    bottom: 105px;
    width:100%;
    padding: 20px;
    border-top: 1px solid #e3e6f0;
    box-shadow:   rgba(0, 0, 0, 0.24) 0px -1px 5px;
    animation: 1s fadeInUp ;
    z-index: 2222;
  
}

input[type="radio"]:checked{
  color:"#42D954"
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}


@media screen and (max-width: 600px) {
    .searchat-chatbot-menu{
      width: 100%;
    }
  
    
  }
  
  .share-icon{
    transition:all 500ms;
    -webkit-transition:all 500ms;
    -moz-transition:all 500ms;
  }
  .share-icon:hover{
    transform: translateY(-5px);
  }