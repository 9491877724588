span {cursor:pointer; }

.minus, .plus{
    width:25px;
    height:25px;
    background:#cd853f;
    border-radius:25px;
    padding:2px;
    /* border:1px solid #ddd; */
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: white;
}
.searchat-qty-input{
    height:25px;
width: 80px;
text-align: center;
font-size: 20px;
    border:1px solid #ddd;
    border-radius:4px;
display: inline-block;
vertical-align: middle;
   } 