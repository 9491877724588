.searchat-bot-message-bubble {
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
    max-width: 70%;
    width: auto;
    animation: fadeInLeft 200ms ease-in-out;
    overflow-wrap: break-word;
  }


  .searchat-bot-message-bubble img {
   object-fit: cover;
 }

 @keyframes fadeInLeft {
   0% {
      opacity: 0;
      transform: translateX(-20px);
   }
   100% {
      opacity: 1;
      transform: translateX(0);
   }
} 