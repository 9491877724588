:root{
    --color-p:#111;
    --color-f:#111;
}


.radiobtn {
	position: relative;
	display: block;
	label {
		display: block;
		background: var(--color-p);
        filter: brightness(125%);
		color: var(--color-f);
		border-radius: 5px;
		padding: 10px 20px;
		border: 3px solid var(--color-p) - 30%;
		margin-bottom: 5px;
		cursor: pointer;
		&:after,
		&:before {
			content: "";
			position: absolute;
			right: 11px;
			top: 11px;
			width: 20px;
			height: 20px;
			border-radius: 3px;
			background: var(--color-p);
		}
		&:before {
			background: transparent;
			transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
				0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
			z-index: 2;
			overflow: hidden;
			background-repeat: no-repeat;
			background-size: 13px;
			background-position: center;
			width: 0;
			height: 0;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
		}
	}
	input[type="radio"] {
		display: none;
		position: absolute;
		width: 100%;
		appearance: none;
		&:checked + label {
			background: var(--color-p);
            filter: brightness(100%);
			animation-name: blink;
			animation-duration: 1s;
			border-color: var(--color-p) - 30%;
            border-width: 3px;
			&:after {
				background: var(--color-p);
			}
			&:before {
				width: 20px;
				height: 20px;
			}
		}
	}
}

