.standard-card{
    margin : auto;
    width: 98%;
    max-width: 400px;
    overflow : hidden;
    border-radius : 20px;
    background : white;
    margin-top:20px;
    margin-bottom:20px;
    transition: all .2s;
    box-shadow:5px 5px 15px rgba(0,0,0, .3);
  }
  
  .standard-card:hover {
    box-shadow:5px 5px 25px rgba(0,0,0, .5);
  }
  
  .standard-card-heading{
    font-size: 18px;
    font-weight: 600;
  }
 

  .standard-card-img-cover{
    position: relative;
  }

  .standard-card img{
    width: 100%;
    height:100%;
    object-fit:cover;
    object-position: 50% 50%;
    
  }

  .standard-card-desc{
    padding : 1.5em ;
   
  }
  .standard-card-desc h1{
    display:inline;
  }

  .standard-card-tdesc{
    margin-bottom:40px;
    width:100%;
    height:50px;
    overflow: auto;
  }

  .standard-card-desc a{
    background-color:#CD853F;
    text-decoration: none;
    padding: 10px;
    color:white;
    border-radius: 15px;
    display:block;
    text-align: center;
    transition: all .2s;
    cursor: pointer;
  }

  .standard-card-desc a:hover{
    background-color:gray;
  }
  