$color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

$circle-size: 20px;
$circle-margin: 7px; 

.circle {
  width: $circle-size;
  height: $circle-size;
  border-radius: 50%;
  margin: $circle-margin;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    opacity: 0.7;
    animation: scale 2s infinite 
      cubic-bezier(0, 0, 0.49, 1.02);
  }
}

@for $i from 1 through 5 {
  .circle-#{$i} {
    background-color: nth($color, $i);
    &:before {
      background-color: nth($color, $i);
      animation-delay: 200ms * $i;
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50%, 75% {
    transform: scale(2.5);
  }
  78%, 100% {
    opacity: 0;
  }
}
.searchat-chatbot-button{
    background-color:#CD853F;
    text-decoration: none;
    padding: 10px;
    color:white;
    border-radius: 15px;
    display:block;
    text-align: center;
    transition: all .2s;
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 6px 6px rgba(0, 0, 0, 0.12);
    
}
.searchat-chatbot-button:active{

  transform: translateY(2px);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.searchat-chatbot-window{
  width: 100%;
}

.searchat-chatbot-footer{
  width: 100%;
}
.searchat-chatbot-branding{
  width: 100%;
}

@media screen and (max-width: 600px) {
  .searchat-chatbot-window{
    width: 100%;
  }
  .searchat-chatbot-footer{
    width: 100%;
  }
  .searchat-chatbot-branding{
    width: 100%;
  }
  
}



.searchat-chatbot-button:hover {
  background-color: gray;
}


.sonar-emitter {
  border-radius: 30px;
  /* background-color: #14BF98; */
}

.sonar-wave {
  position: absolute;
  top: 0;
  left: -5px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: #14BF98;
  opacity: 0;
  z-index: 22222;
  pointer-events: none;
  
}

.sonar-wave1 {
  animation: sonarWave 3s linear infinite;
}

.sonar-wave2 {
  animation: sonarWave 3s 0.5s linear infinite;
}

.sonar-wave3 {
  animation: sonarWave 3s 1s linear infinite;
}

.sonar-wave4 {
  animation: sonarWave 3s 1.5s linear infinite;
}

@keyframes sonarWave {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}



.toggle-button-cover
{
    display: table-cell;
    position: relative;
    width: 200px;
    height: 140px;
    box-sizing: border-box;
}


.button-cover, .knobs, .layer
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.button
{
    position: relative;
    top: 50%;
    width: 74px;
    height: 36px;
    margin: -20px auto 0 auto;
    overflow: hidden;
}

.button.r, .button.r .layer
{
    border-radius: 100px;
}

.button.b2
{
    border-radius: 2px;
}

.checkbox
{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs
{
    z-index: 2;
}

.layer
{
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
}


/* Button 6 */
#button-6
{
    overflow: visible;
}

#button-6 .knobs:before
{
    content: 'ع';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 20px;
    height: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: #03A9F4;
    border-radius: 50%;
}

#button-6 .layer, #button-6 .knobs, #button-6 .knobs:before
{
    transform: rotateZ(0);
    transition: 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-6 .checkbox:checked + .knobs
{
    transform: rotateZ(-180deg);
}

#button-6 .checkbox:checked + .knobs:before
{
    content: 'EN';
    background-color: #f44336;
    transform: rotateZ(180deg);
}

#button-6 .checkbox:checked ~ .layer
{
    background-color: #fcebeb;
    transform: rotateZ(180deg);
}


.resize-chatbot-btn{
  display:inline-block; 
  height:30px;
  width:30px;
  background:rgb(238, 238, 238); 
  color:black;
  font-size:11px;
  padding:8px;
  padding-left: 4px;
  border-right:1px solid gray;
  font-weight:500;
  text-align:center
}


.resize-chatbot-btn:hover{
  
  background:gray; 
  color: white;
  
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

#launcher {
  display: none !important;
}