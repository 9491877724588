table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  
  th, td {
    text-align: center;
    padding: 16px;
  }
  
  th:first-child, td:first-child {
    text-align: left;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2
  }
  
