//default btn color
$btnColor : #eee;
//default transition duration
$duration: .3s;

//disable the highlighting effect when an element is tapped
@mixin disableTapHighlightColor() {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

//improve performance when doing transitions
@mixin hardwareAccel() {
	transform: translateZ(0);
}


/* PULSE */
@keyframes pulse {
  50% {
    transform:scale(0.9);
  }
  100% {
    transform:scale(1.0);
  }
}
.pulse {
  
  @include disableTapHighlightColor();
	 @include hardwareAccel();
     
  
  &:hover {
    font-weight:600;
    color: -50%;
    background-color: rgb(243, 243, 243);
    border-radius: 10px;
    animation-name:pulse;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count:infinite;
    animation-direction:reverse;
  }
}

.searchat-main-menu-chatbot {
  display: inline-block;
  width: 50%;
  height:100px;
  padding: 20px;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  border: 1px solid #eee;
  transition:all 1s;
 -webkit-transition:all 1s;
 -moz-transition:all 1s;
}
