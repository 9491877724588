:root{
  --color-scroll:#111;
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }


  body {
    font-family: 'Poppins', 'Tajawal', sans-serif;
  }

  textarea{
    font-family:  'Poppins','Tajawal', sans-serif;
  }
  input{
    font-family:  'Poppins','Tajawal', sans-serif;
  }

 

  .searchat-btn-div{  
    position: absolute;
    font-size: 24px; 
    color:gray;
    cursor: pointer;
  }

  .searchat-btn-div:hover{   
    color:#CD853F;
  }

  input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
    -webkit-appearance: block;
}

  
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}