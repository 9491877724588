.searchat-user-message-bubble {
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
    max-width: 70%;
    width: auto;
   
    /* margin-bottom: 20px; */
    /* float: right; */
    animation: fadeInRight 200ms ease-in-out;
  }

  .searchat-user-message-bubble img {
   object-fit: cover;
 }

  @keyframes fadeInRight {
    0% {
       opacity: 0;
       transform: translateX(-20px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 }