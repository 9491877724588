
  .searchat-carousel-div{
    animation: fadeInDown 1s ease-in-out;
    width: 100%;
    margin: auto;
  }
  
  .swiper-slide{
   -webkit-transform: translateZ(0);
   -webkit-backface-visibility: hidden;
  }
  @keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(20px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 }

 .swiper-button-next{
    color: gray !important;
 }
.swiper-button-prev{
   color: gray !important;
}