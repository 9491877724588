.inner
{
    display: inline-block;
    align-items: center;
    align-content: center;
}

button {
	padding:10px 25px; 
	border:0.2px ;
	cursor:pointer;
	border-radius: 5px;
  font-size:15px;
  width: 110px ;
  margin: 2px;
}


@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto&display=swap');


.card{
  width: 100%;
  height: 100%;
  position : relative;
  border-radius: 7px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
  /* margin-bottom: 200px; */
  /* padding: 23px; */
}

.card .card__container{
  padding : 2rem;
  width: 100%;
  height: 100%;
  position: relative;
  background:white;

}
.card .card__header{
  margin-bottom: 1rem;
  font-family: 'Playfair Display', serif;
}

.card .card__body{
  font-family: 'Roboto', sans-serif;
  word-wrap: break-word;
  text-align-last: center;
  white-space: wrap;

}

.card::after{
  position: absolute;
  content: attr(data-label);
  top: 11px;
  right: -14px;
  padding: 0.5rem;
  width: 10rem;
  color: white;
  background-color:rgb(90, 90, 90);
  text-align: center;
  font-family: 'Roboto', sans-serif;
  box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
}

@media screen and (max-width:500px) {
  button {
    width:60px;
    padding:5px 12px; 
    display: inline-block;
}
}
@media screen and (max-width: 400px) {
  button {
    width:60px;
    padding:5px 12px; 
    display: inline-block;
}
/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
  h4 ,li {
    font-size: 80px;
    word-spacing: 3px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  h4 , li {
    font-size: 18px;
    word-spacing: 3px;
  }
}
@media screen and (min-width: 375px) {
  h4 , li ,p{
    font-size: 15px;
    word-spacing: 3px;
  }
}

@media screen and (max-width: 340px) {
  h4 , li ,p{
    font-size: 14px;
    word-spacing: 0.5px;
  }
}
}